import * as types from '../constants/actionTypes'
import { fetchTerminals } from './terminals'
import { compose } from '../utils/compose'
import { constant } from '../utils/constant'
import { handleError } from '../utils/handle-error'
import {
    assignOnDemandRoutesToLanes as assignOnDemandRoutes,
    assignRoutesToLanes as assignRoutes,
    backorderMissingParcels as backorder,
    createDateSortingRule,
    createTemporalSortingRule,
    createLockerLinehaulSortingRule,
    createLockerSortingRule,
    createMerchantPrioritySortingRule,
    createReadFailSortingRule,
    createReturnLinehaulSortingRule,
    createLinehaulHubOrRegionSortingRule,
    createRouteSortingRule,
    createOnDemandRouteSortingRule,
    createLockerStopSortingRule,
    createSortingRules,
    createTerminalSortingRule,
    createOversizeLockerParcelSortingRule,
    createReturnToMerchantSortingRule,
    deleteAllRules,
    deleteDateSortingRule,
    deleteTemporalSortingRule,
    deleteLockerLinehaulSortingRule,
    deleteSortingRule,
    deleteLockerSortingRule,
    deleteReadFailSortingRule,
    deleteReturnLinehaulSortingRule,
    deleteRouteSortingRule,
    deleteTerminalSortingRule,
    deleteOversizeLockerParcelSortingRule,
    editMaximumNumberOfRoutesPerLane,
    editMinimumNumberOfRoutesPerLane,
    editSortingMachineAssignDirection,
    getRules,
    getLockersForSorting,
    getLockerSortingRules,
    getRouteSortingRules,
    getSortingLanes,
    getSortingProfiles,
    listCurrentRouteSortingStatuses,
    setApplySelectedSortingProfile,
    setBookDeliveryAutomatically,
    setLaneEnabled,
    setSortingProfile,
    setBookDelivery,
    getBookDelivery,
    getProfileRules,
    createProfileSortingRule,
    deleteProfileSortingRule,
    createProfileSortingRules,
    deleteProfileSortingRules,
    getBackorderNonVerifiedParcels,
} from '../utils/terminal-sorting-webapi'
import {
    DATE,
    LOCKER,
    LOCKER_LINEHAUL,
    LOCKER_STOP,
    MERCHANT_PRIORITY,
    OVERSIZE_LOCKER_PARCEL,
    READ_FAIL,
    RETURN_LINEHAUL,
    TEMPORAL,
    LINEHAUL,
    RETURN_TO_MERCHANT,
    LINEHAUL_HUB_OR_REGION,
} from '../utils/terminal-sorting-rule-types'
import { fetchAllHubPallets } from '../utils/hubs-webapi'
import { getTodaysIncompleteEcommerceRoutes, getLockerStops, getLockerRoutes } from '../utils/route-webapi'

export const allRulesLoaded = (response) => ({
    type: types.ALL_RULES_LOADED,
    status: 200,
    response,
})

export const rulesLoading = () => ({
    type: types.RULES_LOADING,
    status: 200,
})

export const routeRulesLoaded = (rules, routes) => ({
    type: types.ROUTE_RULES_LOADED,
    status: 200,
    rules,
    routes,
})

export const profileRulesLoading = () => ({
    type: types.PROFILE_RULES_LOADING,
})

export const profileRulesLoaded = (response) => ({
    type: types.PROFILE_RULES_LOADED,
    status: 200,
    response,
})

export const sortingProfilesLoaded = (response) => ({
    type: types.SORTING_PROFILES_LOADED,
    status: 200,
    response,
})

export const editingProfile = () => ({
    type: types.EDITING_PROFILE,
})

export const editedProfile = () => ({
    type: types.EDITED_PROFILE,
    status: 200,
})

export const deletingAllRulesFromProfile = () => ({
    type: types.DELETING_ALL_RULES_FROM_PROFILE,
})

export const deletedAllRulesFromProfile = () => ({
    type: types.DELETED_ALL_RULES_FROM_PROFILE,
})

export const deleteAllRulesFromProfileFailed = () => ({
    type: types.DELETE_ALL_RULES_FROM_PROFILE_FAILED,
})

export const lockerRulesLoaded = (response) => ({
    type: types.LOCKER_RULES_LOADED,
    status: 200,
    response,
})

export const lockerStopsLoaded = (response) => ({
    type: types.LOCKER_STOPS_LOADED,
    status: 200,
    response,
})

export const gettingLockerStops = (response) => ({
    type: types.LOCKER_STOPS_LOADED,
    status: 200,
    response,
})

export const lockerRoutesLoaded = (response) => ({
    type: types.LOCKER_ROUTES_LOADED,
    status: 200,
    response,
})

export const ruleCreated = (response) => ({
    type: types.RULE_CREATED,
    status: response.status,
    response,
})

export const ruleCreateFail = ({ status }) => ({
    type: types.RULE_CREATE_FAIL,
    status,
    message: 'Could not create rule.',
})

export const ruleDeleteFail = ({ status }) => ({
    type: types.RULE_DELETE_FAIL,
    status,
    message: 'Could not delete rule.',
})

export const assigningOnDemandRoutesToLanes = () => ({
    type: types.ASSIGNING_ON_DEMAND_ROUTES_TO_LANES,
})

export const assignedOnDemandRoutesToLanes = () => ({
    type: types.ASSIGNED_ON_DEMAND_ROUTES_TO_LANES,
})

export const assignOnDemandRoutesToLanesFailed = () => ({
    type: types.ASSIGN_ON_DEMAND_ROUTES_TO_LANES_FAILED,
})

export const assigningRoutesToLanes = () => ({
    type: types.ASSIGNING_ROUTES_TO_LANES,
})

export const assignedRoutesToLanes = () => ({
    type: types.ASSIGNED_ROUTES_TO_LANES,
})

export const assignRoutesToLanesFailed = () => ({
    type: types.ASSIGN_ROUTES_TO_LANES_FAILED,
})

export const deletingAllRulesFromLanes = () => ({
    type: types.DELETING_ALL_RULES_FROM_LANES,
})

export const deletedAllRulesFromLanes = () => ({
    type: types.DELETED_ALL_RULES_FROM_LANES,
})

export const deleteAllRulesFromLanesFailed = () => ({
    type: types.DELETE_ALL_RULES_FROM_LANES_FAILED,
})

export const bookDeliveryLoaded = (response) => ({
    type: types.BOOK_DELIVERY_LOADED,
    status: 200,
    response,
})

export const bookDeliveryFailed = () => ({
    type: types.BOOK_DELIVERY_FAILED,
    status: 404,
})

export const dataLoaded = (
    rules,
    lockerRoutes,
    terminalLanes,
    sortingProfiles,
    routeRules,
    lockerRules,
    routeSortingStatuses,
    routes,
    lockers,
    hubPallets,
    nonVerifiedParcels,
) => ({
    type: types.RULES_LOADED,
    status: 200,
    rules,
    lockerRoutes,
    terminalLanes,
    sortingProfiles,
    routeRules,
    lockerRules,
    routeSortingStatuses,
    routes,
    lockers,
    hubPallets,
    nonVerifiedParcels,
})

export const dataReset = () => ({
    type: types.RULES_RESET,
})

export const unifiedTerminalsWithBackorderingActive = [
    29, // STOCKHOLM_BRUNNA
    85, // OSLO
]

export const fetchData = (terminalId) => (dispatch) => {
    const promises = [
        getRules(terminalId),
        getLockerRoutes(terminalId),
        getSortingLanes(terminalId),
        getSortingProfiles(terminalId),
        getRouteSortingRules(terminalId),
        getLockerSortingRules(terminalId),
        listCurrentRouteSortingStatuses(terminalId),
        getTodaysIncompleteEcommerceRoutes(terminalId),
        getLockersForSorting(terminalId),
        fetchAllHubPallets(terminalId),
    ]

    if (unifiedTerminalsWithBackorderingActive.includes(terminalId)) {
        promises.push(getBackorderNonVerifiedParcels(terminalId))
    }

    return Promise.all(promises)
        .then(
            ([
                rules,
                lockerRoutes,
                terminalLanes,
                sortingProfiles,
                routeRules,
                lockerRules,
                routeSortingStatuses,
                incompleteRoutes,
                lockers,
                hubPallets,
                nonVerifiedParcels,
            ]) => {
                const routes = incompleteRoutes.filter(({ completedSortingAt }) => !completedSortingAt)
                return dispatch(
                    dataLoaded(
                        rules,
                        lockerRoutes,
                        terminalLanes,
                        sortingProfiles,
                        routeRules,
                        lockerRules,
                        routeSortingStatuses,
                        routes,
                        lockers,
                        hubPallets,
                        nonVerifiedParcels,
                    ),
                )
            },
        )
        .catch(handleError)
}

export const getAllRules = (terminalId) => (dispatch) =>
    getRules(terminalId)
        .then(compose(dispatch, allRulesLoaded))
        .catch(handleError)

export const getAllProfileRules = (terminalId, profile, useLoader = true) => (dispatch) => {
    if (useLoader) {
        dispatch(profileRulesLoading())
    }
    return getProfileRules(terminalId, profile)
        .then(compose(dispatch, profileRulesLoaded))
        .catch(handleError)
}

export const getAllLockerStops = (routeId) => (dispatch) => {
    getLockerStops(routeId)
        .then(compose(dispatch, lockerStopsLoaded))
        .catch(handleError)
}

export const getAllLockerRoutes = (terminalId) => (dispatch) =>
    getLockerRoutes(terminalId)
        .then(compose(dispatch, lockerRoutesLoaded))
        .catch(handleError)

export const getAllRouteRules = (terminalId) => (dispatch) => {
    const promises = [getRouteSortingRules(terminalId), getTodaysIncompleteEcommerceRoutes(terminalId)]

    return Promise.all(promises)
        .then(([rules, incompleteRoutes]) => dispatch(routeRulesLoaded(rules, incompleteRoutes)))
        .catch(handleError)
}

export const getAllSortingProfiles = (terminalId) => (dispatch) =>
    getSortingProfiles(terminalId)
        .then(compose(dispatch, sortingProfilesLoaded))
        .catch(handleError)

export const applySelectedSortingProfile = (terminalId, profile) => (dispatch) =>
    setApplySelectedSortingProfile(terminalId, profile)
        .then((response) => {
            compose(dispatch, fetchData, constant(terminalId))
            return response
        })
        .catch(handleError)

export const editSortingProfile = (terminalId, profile) => (dispatch) => {
    dispatch(editingProfile())
    return setSortingProfile(terminalId, profile)
        .then(() => {
            dispatch(editedProfile())
            dispatch(getAllProfileRules(terminalId, profile))
        })
        .catch(handleError)
}

export const getAllLockerRules = (terminalId) => (dispatch) =>
    getLockerSortingRules(terminalId)
        .then(compose(dispatch, lockerRulesLoaded))
        .catch(handleError)

export const getBookDeliveryValues = (terminalId) => (dispatch) =>
    getBookDelivery(terminalId)
        .then(compose(dispatch, bookDeliveryLoaded))
        .catch(compose(dispatch, bookDeliveryFailed, handleError))

export const createDateRule = (terminalId, laneId, date, recurring, lesser, greater) => (dispatch) =>
    createDateSortingRule(laneId, date, recurring, lesser, greater)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createTemporalRule = (terminalId, laneId, temporal) => (dispatch) =>
    createTemporalSortingRule(laneId, temporal)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createRouteRule = (terminalId, laneId, routeId) => (dispatch) =>
    createRouteSortingRule(laneId, routeId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createOnDemandRouteRule = (terminalId, laneId, routeId) => (dispatch) =>
    createOnDemandRouteSortingRule(terminalId, laneId, routeId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createTerminalRule = (terminalId, laneId, distributionTerminalId) => (dispatch) =>
    createTerminalSortingRule(laneId, distributionTerminalId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createReturnLinehaulRule = (terminalId, laneId, destinationTerminalId) => (dispatch) =>
    createReturnLinehaulSortingRule(laneId, destinationTerminalId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createLockerRule = (terminalId, laneId, lockerId) => (dispatch) =>
    createLockerSortingRule(laneId, lockerId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createLockerStopRule = (terminalId, laneId, lockerStopId) => (dispatch) =>
    createLockerStopSortingRule(terminalId, laneId, lockerStopId)
        .then(compose(dispatch, getAllLockerRoutes, constant(terminalId)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createRules = (terminalId, laneId, rules) => (dispatch) =>
    createSortingRules(terminalId, laneId, rules)
        .then(compose(dispatch, getAllLockerRoutes, constant(terminalId)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createLockerLinehaulRule = (terminalId, laneId, distributionTerminalId) => (dispatch) =>
    createLockerLinehaulSortingRule(laneId, distributionTerminalId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createMerchantPriorityRule = (terminalId, laneId, merchantId, merchantGroupId, exclusivePriority) => (
    dispatch,
) =>
    createMerchantPrioritySortingRule(terminalId, laneId, merchantId, merchantGroupId, exclusivePriority)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createReadFailRule = (terminalId, laneId) => (dispatch) =>
    createReadFailSortingRule(laneId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createOversizeLockerParcelRule = (terminalId, laneId) => (dispatch) =>
    createOversizeLockerParcelSortingRule(laneId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createReturnToMerchantRule = (terminalId, laneId, merchantId, merchantGroupId) => (dispatch) =>
    createReturnToMerchantSortingRule(terminalId, laneId, merchantId, merchantGroupId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createLinehaulHubOrRegionRule = (
    terminalId,
    terminalSortingLaneId,
    destinationTerminalId,
    destinationType,
) => (dispatch) =>
    createLinehaulHubOrRegionSortingRule(terminalId, terminalSortingLaneId, destinationTerminalId, destinationType)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const deleteDateRule = (terminalId, ruleId) => (dispatch) =>
    deleteDateSortingRule(ruleId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleDeleteFail, handleError))

export const deleteTemporalRule = (terminalId, ruleId) => (dispatch) =>
    deleteTemporalSortingRule(ruleId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleDeleteFail, handleError))

export const deleteRouteRule = (terminalId, ruleId) => (dispatch) =>
    deleteRouteSortingRule(ruleId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleDeleteFail, handleError))

export const deleteOnDemandRouteRule = (terminalId, ruleId) => (dispatch) =>
    deleteSortingRule(ruleId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleDeleteFail, handleError))

export const deleteTerminalRule = (terminalId, ruleId) => (dispatch) =>
    deleteTerminalSortingRule(ruleId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleDeleteFail, handleError))

export const deleteReturnLinehaulRule = (terminalId, ruleId) => (dispatch) =>
    deleteReturnLinehaulSortingRule(ruleId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleDeleteFail, handleError))

export const deleteLinehaulHubOrRegionRule = (terminalId, ruleId) => (dispatch) =>
    deleteSortingRule(ruleId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleDeleteFail, handleError))

export const deleteLockerRule = (terminalId, ruleId) => (dispatch) =>
    deleteLockerSortingRule(ruleId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleDeleteFail, handleError))

export const deleteLockerLinehaulRule = (terminalId, ruleId) => (dispatch) =>
    deleteLockerLinehaulSortingRule(ruleId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleDeleteFail, handleError))

export const deleteLockerStopRule = (terminalId, ruleId) => (dispatch) =>
    deleteSortingRule(ruleId)
        .then(compose(dispatch, getAllLockerRoutes, constant(terminalId)))
        .catch(compose(dispatch, ruleDeleteFail, handleError))

export const deleteMerchantPriorityRule = (terminalId, ruleId) => (dispatch) =>
    deleteSortingRule(ruleId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleDeleteFail, handleError))

export const deleteReturnToMerchantRule = (terminalId, ruleId) => (dispatch) =>
    deleteSortingRule(ruleId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleDeleteFail, handleError))

export const deleteReadFailRule = (terminalId, ruleId) => (dispatch) =>
    deleteReadFailSortingRule(ruleId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleDeleteFail, handleError))

export const deleteOversizeLockerParcelRule = (terminalId, ruleId) => (dispatch) =>
    deleteOversizeLockerParcelSortingRule(ruleId)
        .then(compose(dispatch, getAllRules, constant(terminalId)))
        .catch(compose(dispatch, ruleDeleteFail, handleError))

export const deleteAllRulesFromLanes = (terminalId) => (dispatch) => {
    dispatch(deletingAllRulesFromLanes())
    deleteAllRules(terminalId)
        .then(() => {
            dispatch(deletedAllRulesFromLanes())
            dispatch(getAllRules(terminalId))
        })
        .catch(compose(dispatch, deleteAllRulesFromLanesFailed, handleError))
}

export const toggleBookDeliverySetting = (terminalId, enabled) => (dispatch) =>
    setBookDeliveryAutomatically(terminalId, enabled)
        .then(compose(dispatch, fetchTerminals))
        .catch(handleError)

export const toggleBookDeliverySettings = (terminalId, data) => (dispatch) =>
    setBookDelivery(terminalId, data)
        .then(compose(dispatch, getBookDeliveryValues, constant(terminalId)))
        .catch(handleError)

export const setSortingMachineAssignDirection = (terminalId, direction) => () =>
    editSortingMachineAssignDirection(terminalId, direction).catch(handleError)

export const setMinimumNumberOfRoutesPerLane = (terminalId, value) => () =>
    editMinimumNumberOfRoutesPerLane(terminalId, value).catch(handleError)

export const setMaximumNumberOfRoutesPerLane = (terminalId, value) => () =>
    editMaximumNumberOfRoutesPerLane(terminalId, value).catch(handleError)

export const backorderMissingParcels = (terminalId) => (dispatch) =>
    backorder(terminalId)
        .then(compose(dispatch, fetchData, constant(terminalId)))
        .catch(handleError)

export const toggleLaneEnabled = (terminalId, laneId, enabled) => () =>
    setLaneEnabled(terminalId, laneId, enabled).catch(handleError)

export const assignOnDemandRoutesToLanes = (terminalId, laneIds, merchant) => (dispatch) => {
    dispatch(assigningOnDemandRoutesToLanes())
    return assignOnDemandRoutes(terminalId, laneIds, merchant)
        .then(() => {
            dispatch(assignedOnDemandRoutesToLanes())
            dispatch(fetchData(terminalId))
        })
        .catch((e) => {
            dispatch(assignOnDemandRoutesToLanesFailed())
            handleError(e)
        })
}

export const assignRoutesToLanes = (terminalId) => (dispatch) => {
    dispatch(assigningRoutesToLanes())
    return assignRoutes(terminalId)
        .then(() => {
            dispatch(assignedRoutesToLanes())
            dispatch(fetchData(terminalId))
        })
        .catch((e) => {
            dispatch(assignRoutesToLanesFailed())
            handleError(e)
        })
}

export const createProfileDateRule = (
    terminalId,
    profile,
    terminalSortingLaneId,
    date,
    recurToday,
    lesserThan,
    greaterThan,
) => (dispatch) =>
    createProfileSortingRule(terminalId, profile, terminalSortingLaneId, DATE, {
        date,
        recurToday,
        lesserThan,
        greaterThan,
    })
        .then(() => dispatch(getAllProfileRules(terminalId, profile, false)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createProfileTemporalRule = (terminalId, profile, terminalSortingLaneId, temporal) => (dispatch) =>
    createProfileSortingRule(terminalId, profile, terminalSortingLaneId, TEMPORAL, { temporal })
        .then(() => dispatch(getAllProfileRules(terminalId, profile, false)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createProfileLockerRule = (terminalId, profile, terminalSortingLaneId, lockerId) => (dispatch) =>
    createProfileSortingRule(terminalId, profile, terminalSortingLaneId, LOCKER, { lockerId })
        .then(() => dispatch(getAllProfileRules(terminalId, profile, false)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createProfileLockerLinehaulRule = (terminalId, profile, terminalSortingLaneId, distributionTerminalId) => (
    dispatch,
) =>
    createProfileSortingRule(terminalId, profile, terminalSortingLaneId, LOCKER_LINEHAUL, {
        terminalId: distributionTerminalId,
    })
        .then(() => dispatch(getAllProfileRules(terminalId, profile, false)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createProfileLockerStopRule = (terminalId, profile, terminalSortingLaneId, lockerStopId) => (dispatch) =>
    createProfileSortingRule(terminalId, profile, terminalSortingLaneId, LOCKER_STOP, { lockerStopId })
        .then(() => dispatch(getAllProfileRules(terminalId, profile, false)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createProfileMerchantPriorityRule = (
    terminalId,
    profile,
    terminalSortingLaneId,
    merchantId,
    merchantGroupId,
    exclusivePriority,
) => (dispatch) =>
    createProfileSortingRule(terminalId, profile, terminalSortingLaneId, MERCHANT_PRIORITY, {
        merchantId,
        merchantGroupId,
        exclusivePriority,
    })
        .then(() => dispatch(getAllProfileRules(terminalId, profile, false)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createProfileRules = (terminalId, profile, terminalSortingLaneId, rules) => (dispatch) =>
    createProfileSortingRules(terminalId, profile, terminalSortingLaneId, rules)
        .then(() => dispatch(getAllProfileRules(terminalId, profile, false)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createProfileTerminalRule = (terminalId, profile, terminalSortingLaneId, distributionTerminalId) => (
    dispatch,
) =>
    createProfileSortingRule(terminalId, profile, terminalSortingLaneId, LINEHAUL, {
        terminalId: distributionTerminalId,
    })
        .then(() => dispatch(getAllProfileRules(terminalId, profile, false)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createProfileReturnLinehaulRule = (terminalId, profile, terminalSortingLaneId, distributionTerminalId) => (
    dispatch,
) =>
    createProfileSortingRule(terminalId, profile, terminalSortingLaneId, RETURN_LINEHAUL, {
        terminalId: distributionTerminalId,
    })
        .then(() => dispatch(getAllProfileRules(terminalId, profile, false)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createProfileReadFailRule = (terminalId, profile, terminalSortingLaneId) => (dispatch) =>
    createProfileSortingRule(terminalId, profile, terminalSortingLaneId, READ_FAIL)
        .then(() => dispatch(getAllProfileRules(terminalId, profile, false)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createProfileReturnToMerchantRule = (
    terminalId,
    profile,
    terminalSortingLaneId,
    merchantId,
    merchantGroupId,
) => (dispatch) =>
    createProfileSortingRule(terminalId, profile, terminalSortingLaneId, RETURN_TO_MERCHANT, {
        merchantId,
        merchantGroupId,
    })
        .then(() => dispatch(getAllProfileRules(terminalId, profile, false)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createProfileLinehaulHubOrRegionRule = (
    terminalId,
    profile,
    terminalSortingLaneId,
    groupWarehouseId,
    groupWarehouseType,
) => (dispatch) =>
    createProfileSortingRule(terminalId, profile, terminalSortingLaneId, LINEHAUL_HUB_OR_REGION, {
        groupWarehouseId,
        groupWarehouseType,
    })
        .then(() => dispatch(getAllProfileRules(terminalId, profile, false)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const createProfileOversizeLockerParcelRule = (terminalId, profile, terminalSortingLaneId) => (dispatch) =>
    createProfileSortingRule(terminalId, profile, terminalSortingLaneId, OVERSIZE_LOCKER_PARCEL)
        .then(() => dispatch(getAllProfileRules(terminalId, profile, false)))
        .catch(compose(dispatch, ruleCreateFail, handleError))

export const deleteProfileRule = (terminalId, profile, ruleId) => (dispatch) =>
    deleteProfileSortingRule(terminalId, profile, ruleId)
        .then(() => dispatch(getAllProfileRules(terminalId, profile, false)))
        .catch(compose(dispatch, ruleDeleteFail, handleError))

export const deleteProfileRules = (terminalId, profile) => (dispatch) => {
    dispatch(deletingAllRulesFromProfile())
    deleteProfileSortingRules(terminalId, profile)
        .then(() => {
            dispatch(deletedAllRulesFromProfile())
            dispatch(getAllProfileRules(terminalId, profile, false))
        })
        .catch(compose(dispatch, deleteAllRulesFromProfileFailed, handleError))
}
