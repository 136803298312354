import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Col, ProgressBar, Row } from 'react-bootstrap'
import Loader from 'react-loader'
import PropTypes from 'prop-types'

import auth from '../../../../../auth'
import { backorderMissingParcels, unifiedTerminalsWithBackorderingActive } from '../../../../../actions/terminal-rules'
import { backorderNonVerifiedParcels } from '../../../../../utils/terminal-sorting-webapi'

const calculateProgress = (routeSortingStatuses) => ({
    numberOfParcels: routeSortingStatuses.reduce((res, r) => res + r.parcels, 0),
    numberOfScannedParcels: routeSortingStatuses.reduce((res, r) => res + r.scanned, 0),
    numberOfSortedParcels: routeSortingStatuses.reduce((res, r) => res + r.sorted, 0),
})

class SortingProgress extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isBackordering: false,
            ...calculateProgress(props.routeSortingStatuses),
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.routeSortingStatuses !== nextProps.routeSortingStatuses) {
            this.setState({ ...calculateProgress(nextProps.routeSortingStatuses) })
        }
    }

    getNumberOfMissingParcels() {
        const { nonVerifiedParcels, selectedTerminalId } = this.props
        const { numberOfParcels, numberOfScannedParcels } = this.state
        let missingParcels = 0
        if (unifiedTerminalsWithBackorderingActive.includes(selectedTerminalId)) {
            missingParcels = nonVerifiedParcels.length
        } else {
            missingParcels = numberOfParcels - numberOfScannedParcels
        }
        return missingParcels
    }

    backorder = () => {
        const { selectedTerminalId } = this.props
        let message = 'Has every Route been sorted for Distribution?'
        message += ` ${this.getNumberOfMissingParcels()} parcels will be backordered`
        if (window.confirm(message)) {
            this.setState({ isBackordering: true })
            if (unifiedTerminalsWithBackorderingActive.includes(selectedTerminalId)) {
                backorderNonVerifiedParcels(selectedTerminalId).then(() => {
                    this.setState({ isBackordering: false })
                })
            } else {
                backorderMissingParcels(selectedTerminalId).then(() => {
                    this.setState({ isBackordering: false })
                })
            }
        }
    }

    renderBackorderButton() {
        const { selectedTerminalId } = this.props
        const { isBackordering } = this.state
        const missingParcels = this.getNumberOfMissingParcels()

        return (
            <Button bsStyle="danger" block disabled={missingParcels === 0 || isBackordering} onClick={this.backorder}>
                {unifiedTerminalsWithBackorderingActive.includes(selectedTerminalId) && (
                    <p>Backorder non verified Parcels</p>
                )}
                {!unifiedTerminalsWithBackorderingActive.includes(selectedTerminalId) && (
                    <p>Backorder missing Parcels</p>
                )}
                <small>Estimation: {missingParcels} Parcels</small>
            </Button>
        )
    }

    render() {
        const { numberOfParcels, numberOfScannedParcels, numberOfSortedParcels } = this.state

        let scannedProgress = Number(((numberOfScannedParcels / numberOfParcels) * 100).toFixed(0))
        let sortingProgress = Number(((numberOfSortedParcels / numberOfParcels) * 100).toFixed(0))

        if (!Number.isFinite(sortingProgress)) {
            sortingProgress = 0
        }

        if (!Number.isFinite(scannedProgress)) {
            scannedProgress = 0
        }

        return (
            <Row>
                <Col xs={6}>
                    <div className="alert alert-route" role="alert">
                        <Loader zIndex={2} scale={0.6} color="#004085" loaded={Boolean(this.props.routesloaded)} />
                        {(!scannedProgress || scannedProgress <= 0) && <span>Nothing has been scanned</span>}
                        {scannedProgress > 0 && scannedProgress < 100 && (
                            <span>Scanning progress {scannedProgress}%</span>
                        )}
                        {scannedProgress === 100 && <span>Everything has been scanned</span>}
                        <div className="progress loading">
                            <ProgressBar now={scannedProgress} />
                        </div>
                    </div>
                </Col>
                <Col xs={6}>
                    <div className="alert alert-route" role="alert">
                        <Loader zIndex={2} scale={0.6} color="#004085" loaded={Boolean(this.props.routesloaded)} />
                        {(!sortingProgress || sortingProgress <= 0) && <span>Nothing has been sorted</span>}
                        {sortingProgress > 0 && sortingProgress < 100 && (
                            <span>Sorting progress {sortingProgress}%</span>
                        )}
                        {sortingProgress === 100 && <span>Everything has been sorted</span>}
                        <div className="progress loading">
                            <ProgressBar now={sortingProgress} />
                        </div>
                        {auth.hasPermission('edit_sorting_machine') && this.renderBackorderButton()}
                    </div>
                </Col>
            </Row>
        )
    }
}

SortingProgress.propTypes = {
    selectedTerminalId: PropTypes.number.isRequired,
    routeSortingStatuses: PropTypes.array.isRequired,
    routesloaded: PropTypes.bool,
    nonVerifiedParcels: PropTypes.array,
}

const mapStateToProps = ({ terminalRules: { routeSortingStatuses, routesloaded, nonVerifiedParcels } }) => ({
    routeSortingStatuses,
    routesloaded,
    nonVerifiedParcels,
})

export default connect(mapStateToProps)(SortingProgress)
